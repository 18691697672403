import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingSa8000Page() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'SA 8000'

  const description =
    'La Certificazione Sociale, identificata dalla sigla SA 8000 (Social Accountability), è uno standard internazionale che elenca i requisiti per un comportamento eticamente corretto delle imprese nei confronti dei lavoratori.<br> È lo standard accreditato riconosciuto a livello internazionale che risponde alle esigenze delle organizzazioni che vogliono distinguersi per il loro impegno nello sviluppo sostenibile e in particolare per le tematiche sociali.<br><br> <b>I capitoli sviluppati nello standard sono:</b><br><br> <ul><li>Lavoro infantile</li> <li>Lavoro obbligato</li> <li>Salute e sicurezza</li> <li>Libertà di associazione, diritto alla contrattazione collettiva</li> <li>Discriminazione</li> <li>Pratiche disciplinari</li> <li>Orario di lavoro</li> <li>Retribuzione</li> <li>Sistema di gestione</li></ul><br> Le aziende che desiderano mantenere un’alta integrità sociale devono controllare e gestire non solo l’impatto sociale prodotto dalle loro attività sul territorio, ma anche controllare e gestire le condizioni lavorative interne e dei propri lavoratori, fornitori e partners.<br> Con la Certificazione di Responsabilità Sociale SA 8000 diventa importante da parte delle Imprese non solo “Cosa si produce” ma anche “Come si produce”<br> ING GROUP assiste numerose imprese su tutto il territorio nazionale nella certificazione e mantenimento di sistemi conformi alla SA 8000.<br><br> <b>VANTAGGI:</b><br><br> <ul><li>Incremento della credibilità, trasparenza e dell’immagine aziendale sul mercato;</li> <li>Maggiore fiducia dei consumatori</li> <li>Miglioramento del rapporto con le istituzioni e le organizzazioni sociali;</li> <li>Controllo dell’eticità e della correttezza sociale nella catena dei fornitori;</li> <li>Miglioramento del clima aziendale;</li> <li>Miglioramento della comunicazione interna ed esterna.'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
